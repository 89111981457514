import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, select } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import { Notification } from 'src/app/graphql/frontend-data-graphql';

import { NotificationUiActions, NotificationApiActions } from './notification.actions';

export interface NotificationState extends EntityState<Notification> {
  selectedId: string | null;
  selectedNotification: Notification | null;
  errors: readonly GraphQLError[];
}

export const notificationAdapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
  selectId: p => p.id,
  sortComparer: false
});

const state: NotificationState = notificationAdapter.getInitialState({ selectedId: null, errors: [], selectedNotification: null });

export const notificationReducer = createReducer<NotificationState>(
  state,
  on(NotificationUiActions.select, (state, { id }): NotificationState => {
    return { ...state, selectedId: id };
  }),
  on(NotificationUiActions.add, (state): NotificationState => {
    return { ...state, selectedId: null, selectedNotification: null };
  }),
  on(NotificationApiActions.findAllSucceeded, (state, { items }): NotificationState => {
    return notificationAdapter.addMany(items, { ...state, errors: [] });
  }),
  on(NotificationApiActions.findOneSucceeded, (state, { item }): NotificationState => {
    return { ...state, selectedNotification: item };
  }),
  on(NotificationApiActions.createSucceeded, NotificationApiActions.updateOneSucceeded, (state, { id }): NotificationState => {
    return { ...state, selectedId: id, errors: [] };
  }),
  on(NotificationApiActions.created, (state, { item }): NotificationState => {
    return notificationAdapter.addOne(item, { ...state });
  }),
  on(NotificationApiActions.updatedOne, (state, { item }): NotificationState => {
    return notificationAdapter.setOne(item, { ...state });
  }),
  on(NotificationApiActions.deleteOneSucceeded, (state, { id }): NotificationState => {
    return { ...state, selectedId: null, errors: [] };
  }),
  on(NotificationApiActions.deletedOne, (state, { id }): NotificationState => {
    return notificationAdapter.removeOne(id, { ...state });
  }),
  on(NotificationApiActions.requestFailed, (state, { errors }): NotificationState => {
    return notificationAdapter.removeAll({ ...state, selectedId: null, errors });
  })
);
