import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import { NotificationFilter, Notification } from 'src/app/graphql/frontend-data-graphql';

export const NotificationUiActions = createActionGroup({
  source: 'Notification UI',
  events: {
    Select: props<{ id: string | null }>(),
    Add: emptyProps(),
    FindAllTriggered: props<{ filter: NotificationFilter }>(),
    CreateTriggered: props<{ input: Notification }>(),
    UpdateOneTriggered: props<{ id: string; update: Notification }>(),
    DeleteOneTriggered: props<{ id: string }>()
  }
});

export const NotificationApiActions = createActionGroup({
  source: 'Notification API',
  events: {
    FindAllSucceeded: props<{ items: Notification[] }>(),
    FindOneSucceeded: props<{ item: Notification }>(),
    CreateSucceeded: props<{ id: string }>(),
    Created: props<{ item: Notification }>(),
    UpdateOneSucceeded: props<{ id: string }>(),
    UpdatedOne: props<{ item: Notification }>(),
    DeleteOneSucceeded: props<{ id: string }>(),
    DeletedOne: props<{ id: string }>(),
    RequestFailed: props<{ errors: readonly GraphQLError[] }>()
  }
});
